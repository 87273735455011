import React, { useState, useEffect } from 'react';
import { DidomiFeatureHeader, DidomiLoader, DidomiIllustration } from '@didomi/ui-atoms-react';
import { useEnvironment, useAuthToken, useActiveOrganization } from '@didomi/utility-react';
import { tw } from '@didomi/ui-foundation';

const App = () => {
  const { apiBaseUrl } = useEnvironment();
  const authToken = useAuthToken();
  const { organizationId } = useActiveOrganization();
  const [dashboardUrl, setDashboardUrl] = useState();
  const [loadingDashboardUrl, setLoadingDashboardUrl] = useState(true);
  const [errorLoadingDashboardUrl, setErrorLoadingDashboardUrl] = useState();
  const [loadedDashboardIframe, setLoadedDashboardIframe] = useState(false);
  const [iframeHeight, setIframeHeight] = useState('0px');

  const loading = loadingDashboardUrl || (!errorLoadingDashboardUrl && !loadedDashboardIframe);

  const resetDashboard = () => {
    setDashboardUrl(null);
    setLoadingDashboardUrl(true);
    setLoadedDashboardIframe(false);
    setIframeHeight('0px');
  };

  /**
   * We load the dashboard-url for the home, we use a simple useEffect + fetch + useState
   * rather than a library since this is the only thing this SPA is doing and the less libraries
   * we can use the better given this will be the first page the users will see.
   */
  useEffect(() => {
    if (apiBaseUrl && authToken && organizationId) {
      const controller = new AbortController();
      const signal = controller.signal;

      resetDashboard();

      const getDashboardUrl = async () => {
        try {
          const dashboardUrlResponse = await fetch(`${apiBaseUrl}/analytics/dashboards-urls?organization_id=${organizationId}&category=home`, {
            signal: signal,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (!dashboardUrlResponse.ok) {
            const errorMessage = await dashboardUrlResponse.json();
            setErrorLoadingDashboardUrl(errorMessage?.message);
          } else {
            const dashboardUrl = await dashboardUrlResponse.json();
            setDashboardUrl(dashboardUrl.data[0].url);
          }

          setLoadingDashboardUrl(false);
        } catch (error) /* istanbul ignore next */ {
          if (error.name == 'AbortError') return;
          setErrorLoadingDashboardUrl(error.name);
          setLoadingDashboardUrl(false);
        }
      };

      getDashboardUrl();

      return () => {
        controller.abort();
      };
    }
  }, [apiBaseUrl, authToken, organizationId]);

  /**
   * We set up the message event listener to capture events sent by the Cluvio window
   */
  useEffect(() => {
    /**
     * Handle message sent by the Cluvio window
     * We use the message to set up the height of the iframe and to show it
     * @param {MessageEvent} e The message event
     */
    const handleCluvioMessage = (e: MessageEvent) => {
      try {
        if (e?.data && typeof e.data === 'string') {
          const embedData = JSON.parse(e?.data);
          const cluvioHeight = embedData?.params?.height;
          if (cluvioHeight) {
            if (!loadedDashboardIframe) {
              setLoadedDashboardIframe(true);
            }
            setIframeHeight(`${cluvioHeight}px`);
          }
        }
      } catch (error) {
        // We don't do anything with the error, many Chrome extensions trigger message events
        // With data properties that cannot be parsed (eg Metamask) thus triggering an exception here
      }
    };

    window.addEventListener('message', handleCluvioMessage);
    return () => window.removeEventListener('message', handleCluvioMessage);
  }, [loadedDashboardIframe]);

  return (
    <div className="flex! flex-col w-full h-full px-l pt-l items-center box-border">
      <DidomiFeatureHeader className="w-full pb-m" titleText="Dashboard" name="dashboard">
        <div slot="description" data-cy="dashboard-description">
          Get an overview of how you use the Didomi solutions
        </div>
      </DidomiFeatureHeader>
      {loading && (
        <div className="flex-1 flex flex-col items-center justify-center">
          <DidomiLoader />
          <div className="font-sans text-body-normal text-primary-blue-6 pt-m">Loading dashboard</div>
        </div>
      )}
      {errorLoadingDashboardUrl ? (
        <div className="flex-1 flex flex-col items-center justify-center">
          <DidomiIllustration name="disclaimer" />
          <div className="font-sans text-body-normal text-primary-blue-6 pt-m">There was an error getting the dashboard information</div>
        </div>
      ) : (
        <div
          className={`${tw(['w-full pb-s'], {
            invisible: loading,
          })}`}
        >
          <iframe
            title="dashboard"
            data-cy="dashboard-iframe"
            data-testid="dashboard-iframe"
            className="border-0 w-full"
            frameBorder="0"
            allowFullScreen
            height={iframeHeight}
            src={dashboardUrl}
          />
        </div>
      )}
    </div>
  );
};

export { App };
